import React, {useEffect, useMemo, useState} from 'react';
import {Autocomplete, Badge, Box, Button, Checkbox, FormControlLabel, List, ListItem, TextField, Chip, Tooltip, Typography} from '@mui/material';
// import TableComponent from './TableComponent'; // TODO: can be deleted as soon as the new method is confirmed
import {Link} from 'react-router-dom';
import dynamicAudience from 'assets/icons/audiences/dynamic_aud.svg';
import staticAudience from 'assets/icons/audiences/static_aud.svg';
import {withStyles} from '@mui/styles';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';
import * as elemental_axios_client from 'util/elemental_axios_client';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {Loader} from 'components/index';
import {useImmer} from 'use-immer';
import MutationLoadingButton from 'components/MutationLoadingButton';
import * as PropTypes from 'prop-types';
import TooltipBigNumber from 'components/TooltipBigNumber';
import humanize from 'humanize-plus';

const dynamicIcon = <img src={dynamicAudience} alt='uploadIcon'/>;
const staticIcon = <img src={staticAudience} alt='uploadIcon'/>;

function MailIcon (props) {
    return null;
}

MailIcon.propTypes = {color: PropTypes.string};
// TODO: could be generalized into "multi value selector" .. not just for audiences
export const AudienceSelector = ({ value, onChange, audiences }) => {

    const [ selectedAudiencesToInclude, setSelectedAudiencesToInclude ] = useState([]);
    const [ selectedAudiences, setSelectedAudiences ] = useImmer(value);
    const [ hasUserChanges, setHasUserChanges ] = useState(false);

    const onClickIncludeAudiences = () => {
        setSelectedAudiences( draft => { draft.push(...selectedAudiencesToInclude); });
        setSelectedAudiencesToInclude([]);
        setHasUserChanges(true);
    };

    const onClickRemoveAudience = ( audienceToRemove ) => {
        setSelectedAudiences( draft => {
            const index = draft.findIndex(audience => audience.id === audienceToRemove.id);
            if (index !== -1) {
                draft.splice(index, 1);
            }
        });
        setHasUserChanges(true);
    };

    useEffect( () => {
        if (onChange && hasUserChanges) {
            onChange(selectedAudiences);
        }
    }, [selectedAudiences]);

    const selectedAudienceIds = selectedAudiences.map(audience => audience.id);
    const availableAudiences = audiences.filter(audience => !selectedAudienceIds.includes(audience.id));
    const selectedAudiencesSorted = [...selectedAudiences].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            <Box display='inline-flex' alignItems='center' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
                { availableAudiences?.length > 0 ? (
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id='multiple-limit-tags'
                        options={availableAudiences}
                        getOptionLabel={(audience) => audience.name }
                        value={selectedAudiencesToInclude}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                fullWidth={true} 
                                label='Choose audiences ...'  
                                fullWidth
                            />
                        )}
                        ListboxProps={{
                            style: { maxHeight: '400px' }
                        }}
                        ListboxComponent={(props) => (
                            <Box>
                                <ul {...props} />
                                <Box 
                                    sx={{ 
                                        position: 'sticky',
                                        bottom: 0,
                                        bgcolor: 'background.paper',
                                        borderTop: '1px solid',
                                        borderColor: 'divider',
                                        p: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const autocompleteElement = document.getElementById('multiple-limit-tags');
                                            if (autocompleteElement) {
                                                autocompleteElement.blur();
                                            }
                                        }}
                                    >
                                        Done
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                {option.profiles_active_email_total && (
                                    <TooltipBigNumber 
                                        number={option.profiles_active_email_total}
                                        compact={true}
                                        chipProps={{
                                            size: "small",
                                            variant: "outlined",
                                            sx: { 
                                                height: '20px',
                                                marginRight: 1,
                                                '& .MuiChip-label': { 
                                                    px: 1,
                                                    fontSize: '0.75rem'
                                                }
                                            }
                                        }}
                                    />
                                )}
                                {option.type === 'dynamic' ? dynamicIcon : staticIcon}
                                {option.name}
                            </li>
                        )}
                        onChange={(e,value) => setSelectedAudiencesToInclude(value)}
                        size='small'
                        sx={{ width: '100%' }}
                    />
                ) : (
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ 
                            fontStyle: 'italic',
                            pl: 1 
                        }}
                    >
                        No more audiences to select
                    </Typography>
                )}
            </Box>
            { selectedAudiencesToInclude.length > 0 &&
                <Box>
                <Button
                    disabled={selectedAudiencesToInclude.length < 1}
                    className='new_primary_button'
                    onClick={onClickIncludeAudiences}
                >Add {selectedAudiencesToInclude.length} audiences</Button>
                </Box>
            }
            { selectedAudiencesSorted.length > 0 &&
                <Box>
                <List>

                    { selectedAudiencesSorted.map( (audience) => {
                        return ( <ListItem key={audience.id}>
                            <Box display="flex" alignItems="center" gap={1} width="100%">
                                {audience.profiles_active_email_total && (
                                    <TooltipBigNumber 
                                        number={audience.profiles_active_email_total}
                                        compact={true}
                                        chipProps={{
                                            size: "small",
                                            variant: "outlined",
                                            sx: { 
                                                height: '20px',
                                                '& .MuiChip-label': { 
                                                    px: 1,
                                                    fontSize: '0.75rem'
                                                }
                                            }
                                        }}
                                    />
                                )}
                                {audience.type === 'dynamic' ? dynamicIcon : staticIcon}
                                <Typography sx={{ flex: 1 }}>{audience.name}</Typography>
                                <Button 
                                    onClick={() => { onClickRemoveAudience(audience);}}
                                    size="small"
                                    sx={{ 
                                        minWidth: 'auto',
                                        px: 1
                                    }}
                                >
                                    remove
                                </Button>
                            </Box>
                        </ListItem> );
                    })
                    }
                </List>
                </Box>
            }
        </>

    );
};


const Audiences = ( { campaign, onSearch, onSave })  => {

    const [selectedAudiences, setSelectedAudiences] = useState([]);

    const queryClient = useQueryClient();

    const { data: allAudiences, isSuccess: allAudiencesSuccess } = useQuery({
        queryKey: ['audiences'],
        queryFn: async () => {
            return await http.get(`/audiences?per_page=1000`);
        }
    });

    const mutationCampaignSetAudiences = useMutation({
        mutationFn: ( audienceIds ) => {
            return elemental_axios_client.post(`/campaigns/${campaign.id}/audiences`, audienceIds);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries( { queryKey: ['campaigns', campaign.id] } );
            onSave();
        }
    });

    const saveAudienceSelector = async () => {
        const audienceIds = selectedAudiences.map(audience => audience.id );
        mutationCampaignSetAudiences.mutate({ segments: audienceIds });
    };

    if (!campaign || !allAudiencesSuccess) {
        return <Loader/>;
    }

    const selectorHasChanges = (JSON.stringify(selectedAudiences.map(a=>a.id).sort()) != JSON.stringify(campaign.segments.map(a=>a.id).sort()));

    return (
        <Box>
            <AudienceSelector audiences={allAudiences.audiences} onChange={setSelectedAudiences} value={campaign.segments} />
            <MutationLoadingButton
                disabled={!selectorHasChanges}
                mutation={mutationCampaignSetAudiences}
                onClick={saveAudienceSelector} color='primary' variant='contained'
                sx={{marginTop: 1, marginRight: 0, width: 'max-content'}}>
                Save Audiences
            </MutationLoadingButton>
        </Box>
    );
};
export default Audiences;
