import React from 'react';
// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// import store1 from './store1';
// import store from './useLocalStore';
import store from './store';
import Root from './Root';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'assets/scss/main.scss';
import 'assets/scss/globals.scss';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {Settings} from 'luxon';
import { ThemeProvider, createTheme} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';


// const sentryDsn = `${process.env.REACT_APP_SENTRY_DSN}`;

// Sentry.init({
//     dsn: sentryDsn,
//     integrations: [new BrowserTracing()],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0
// });
Settings.defaultLocale = 'en-US';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            cacheTime: 60 * 60 * 1000
        }
    }
});

const theme = createTheme();

const useStyles = makeStyles((theme) => {
    root: {
        // some css that access to theme
    }
});


const App = () => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <ThemeProvider theme={theme}>
                        <Root/>
                    </ThemeProvider>
                </LocalizationProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Provider>
    );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App/>);