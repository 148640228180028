import React, { useContext, useState, useEffect } from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import { kratosPublicUrl, apiUrl } from 'constants/apiRoutes';
import { createContext } from 'react';

export const AuthContext = createContext({
  currentKratosSession: null,
  organizations: [],
  currentOrganization: null,
  setCurrentOrganization: () => {},
  switchOrganization: () => Promise.resolve(),
  // ... other existing context values ...
});

export function useAuth () {
    return useContext(AuthContext);
}

export function AuthProvider ({ children }) {

    // holds the valid authorized kratos session based on whoami ( or login payload )
    const [currentKratosSession, setCurrentKratosSession] = useState(null);
    // holds the kratos error ( non-auth ) response based on the whoami lookup
    const [currentKratosError, setCurrentKratosError] = useState(null);
    const [organizations, setOrganizations] = useState(null);
    const [invites, setInvites] = useState(null);
    const [currentOrganization, setCurrentOrganization] = useState(null);
    // tracks if user is authorized and all dependent data has loaded to allow the app to proceed and operate
    const [authIsReady, setAuthIsReady ] = useState(false);
    const queryClient = useQueryClient();

    const queryKratosSession = useQuery({
        queryKey: ['kratos-sessions', 'current'],
        queryFn: () => {
            return axios.get(`${kratosPublicUrl}/sessions/whoami`,  { withCredentials: true} )
                .then(response => response.data );
        },
        onError: (error, variables, context) => {
            localStorage.clear();
            console.log('error session', error.response.data);
            // setCurrentKratosSession(error.response.data);
            setCurrentKratosError(error.response.data);
            setCurrentKratosSession(null);
        }
    },[]);

    useEffect(() => {
        if ( queryKratosSession.data ){
            console.log('setCurrentKratosSession from react-query', queryKratosSession.data );
            setCurrentKratosSession(queryKratosSession.data);
            setCurrentKratosError(null);
        }
    },[queryKratosSession.data]);

    const organizationsQuery = useQuery({
        queryKey: ['organizations', currentKratosSession?.identity?.id],
        queryFn: () => {
            return axios.get(`${apiUrl}/api/v1/identities/current/organizations`, {
                headers: {
                    Accept: 'application/json'
                },
                withCredentials: true
            }).then( res => res.data );
        },
        enabled: currentKratosSession?.active == true
    },[currentKratosSession]);

    const queryInvites = useQuery({
        queryKey: ['invites', currentKratosSession?.identity?.id],
        queryFn: () => {
            return axios.get(`${apiUrl}/api/v1/identities/current/invites/pending`, {
                headers: {
                    Accept: 'application/json'
                },
                withCredentials: true
            }).then( res => res.data );
        },
        // enabled: organizations && organizations.length == 0
        enabled: currentKratosSession?.active == true
    },[currentKratosSession]);

    useEffect(() => {
        if (organizationsQuery.data) {
            console.log('organizationsQuery.data', organizationsQuery.data);
            setOrganizations(organizationsQuery.data);
        }
    }, [organizationsQuery.data]);

    useEffect(() => {
        if (queryInvites.data) {
            console.log('queryInvites.data', queryInvites.data);
            setInvites(queryInvites.data);
        }
    }, [queryInvites.data]);

    useEffect(() => {
        if (organizations && !currentOrganization) {
            // Try to get organization from localStorage first
            const storedOrgStr = localStorage.getItem('organization');
            if (storedOrgStr) {
                try {
                    const storedOrg = JSON.parse(storedOrgStr);
                    // Check if the stored org exists in the current organizations list
                    const matchingOrg = organizations.find(org => org.id === storedOrg.id);
                    if (matchingOrg) {
                        switchOrganization(matchingOrg);
                        return;
                    }
                } catch (e) {
                    console.error('Error parsing stored organization:', e);
                    localStorage.removeItem('organization');
                }
            }
            // Fall back to first organization if no stored org or stored org not found
            if (organizations.length > 0) {
                switchOrganization(organizations[0]);
            } else {
                setCurrentOrganization(null);
            }
        }
    }, [organizations, currentOrganization]);

    useEffect(() => {
        if (currentOrganization){
            const organizationJsonStr = JSON.stringify(currentOrganization);
            localStorage.setItem('organization', organizationJsonStr);

            // sets the axios default based on this url
            axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1/${currentOrganization.id}`;
            axios.defaults.withCredentials = true;
            axios.defaults.credentials = 'include';

            // const settingsZone = response?.setting_values?.timezone?.value;
            // localStorage.setItem('default_timezone', JSON.stringify((settingsZone && settingsZone.length > 0) ? settingsZone : 'system'));
            // luxon settings
            // Settings.defaultZone = (settingsZone && settingsZone.length > 0) ? settingsZone : 'system';

        }
        else {
            console.log('currentOrganization is null');
            // should probably clear any changes
        }
    },[currentOrganization]);

    // if there is no session, auth can be ready ( just as noauth ) and orgs/invites empty
    useEffect( () => {
        if( currentKratosError ) {
            console.log('kratosAuthError - setting authIsReady to false');
            setAuthIsReady(false);
            setOrganizations(null);
            setInvites(null);
        }
    }, [currentKratosError] );

    useEffect( () => {
        if(currentKratosSession !== null && organizations !== null && invites !== null ) {
            console.log('setting authIsReady to true');
            console.log('currentKratosSession', currentKratosSession);
            console.log('organizations', organizations);
            console.log('invites', invites);
            setAuthIsReady(true);
        }
    },[currentKratosSession, organizations, invites]);

    const clearAuthState = () => {
        queryClient.clear();
        setAuthIsReady(false);
        setCurrentKratosSession(null);
        setCurrentOrganization(null);
        setOrganizations(null);
        setInvites(null);
        localStorage.removeItem('organization');
    };

    const logout = async (postLogoutUrl = '/login') => {
        try {
            // Get the logout URL from Kratos
            const response = await fetch(`${kratosPublicUrl}/self-service/logout/browser`, {
                method: 'GET',
                headers: { Accept: 'application/json' },
                credentials: 'include'
            });
            const data = await response.json();

            // Perform the logout and wait for completion
            const logoutResponse = await fetch(data.logout_url, {
                method: 'GET',
                headers: { Accept: 'application/json' },
                credentials: 'include'
            });
            
            // Ensure the logout request was successful
            if (!logoutResponse.ok) {
                throw new Error('Logout request failed');
            }

            console.log('logoutResponse', logoutResponse);
            console.log('logout from kratos complete - clear state');

            clearAuthState();
            
            console.log('redirect to ', postLogoutUrl);
            // Redirect to specified URL or login
            window.location.href = postLogoutUrl;
        } catch (error) {
            console.error('Logout failed:', error);
            // clear auth state in either case
            clearAuthState();
        }
    };

    const switchOrganization = async (newOrg) => {
        await setCurrentOrganization(newOrg);
        
        // Set axios defaults
        axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1/${newOrg.id}`;
        axios.defaults.withCredentials = true;
        axios.defaults.credentials = 'include';
        
        // Update localStorage
        const organizationJsonStr = JSON.stringify(newOrg);
        localStorage.setItem('organization', organizationJsonStr);

        // Invalidate relevant queries that might depend on org context
        await queryClient.invalidateQueries();
        
        return true;
    };

    const value = {
        authIsReady,
        currentKratosSession,
        setCurrentKratosSession,
        currentKratosError,
        organizations,
        invites,
        setOrganizations,
        logout,
        currentOrganization,
        setCurrentOrganization,
        switchOrganization,
    };

    return (
        <AuthContext.Provider value={value}>
            {(currentKratosSession || currentKratosError) && children}
        </AuthContext.Provider>
    );
}
