import React from 'react';
import merge from 'lodash/merge';
import MuiConfig from 'react-awesome-query-builder/lib/config/mui';

import CustomDateComponent from './CustomComponents/CustomDateComponent.js';
import CustomBooleanComponent from './CustomComponents/CustomBooleanComponent.js';
import {NumberOverTimeFrame, DateRange, ProfilePropChangeWidget} from 'components/QueryBuilder/CustomComponents/CustomComponents';
import {AsyncFetchListValuesFn, ListValues} from 'react-awesome-query-builder/lib';
import * as http from '../../util/elemental_axios_client';


const getQueryBuilderConfig = ( { profileProps }) => {

    console.log('getQueryBuilderConfig called');

    // TODO: i think the simple thing here is it should be a hook?
    const getPmtaQueues = async () => {
        const values = {};
        const pmta_queues = await http.get('/pmta_queues');
        pmta_queues.forEach((item) => {
            const {name, id} = item;
            values[id] = name;
        });
        return values;
    };

    // Field definitions for filtering on profile property value .. the data type is set based on the
    // the profile prop data type
    let profile_prop_value_fields = {};
    if (profileProps) {
        for (let item of profileProps) {
            let field = {label: item.name_friendly};
            switch (item.prop_data_type) {
            case 'string':
                field.type = 'text';
                break;
            case 'integer':
                field.type = 'number';
                field.fieldSettings = {min: '1'};
                break;
            case 'decimal':
                field.type = 'number';
                field.fieldSettings = {min: '1', step: '0.1'};
                break;
            case 'boolean':
                field.type = 'customBoolean';
                break;
            case 'date':
                field.type = 'customDate';
                break;
            case 'array':
                // TOOD: should be a multi-select using the value options from the profile_prop
                field.type = 'text';
                break;
            default:
                break;
            }
            profile_prop_value_fields[`${item.name}`] = field;
        }
    }

    // Sort profile_prop_value_fields by key
    const sortedProfilePropValueFields = Object.fromEntries(
        Object.entries(profile_prop_value_fields).sort(([a], [b]) => a.localeCompare(b))
    );

    let profile_prop_change_fields = {};
    for (let item of profileProps) {
        profile_prop_change_fields[`${item.name}.${item.prop_data_type}`] = {
            label: item.name_friendly,
            type: 'profile_prop_change',
            fieldSettings: {prop_data_type: item.prop_data_type}
        };
    }

    const fields = {


        profile_metric: {
            label: 'Person actions', // 'People who have'
            type: '!struct',
            subfields: {

                profile_event: {
                    label: 'profile event',
                    type: '!group',
                    subfields: {
                        purchased: {
                            label: 'Purchased',
                            type: 'number_over_timeframe',
                            valueSources: ['value']
                        },
                    }
                },

                email_metric: {
                    label: 'engaged with email',
                    type: '!group',
                    subfields: {
                        opened: {
                            label: 'Opened',
                            type: 'number_over_timeframe',
                            valueSources: ['value']
                        },
                        clicked: {
                            label: 'Clicked',
                            type: 'number_over_timeframe',
                            valueSources: ['value']
                        },
                        bounced: {
                            label: 'Bounced',
                            type: 'number_over_timeframe',
                            valueSources: ['value']
                        },
                        spam: {
                            label: 'Marked as spam',
                            type: 'number_over_timeframe',
                            valueSources: ['value']
                        }
                    }
                },
                profile_prop_change: {
                    label: 'changed property', //  'People with changed property value'
                    type: '!group',
                    subfields: profile_prop_change_fields
                },
                shop_metric: {
                    label: 'interacted with shop',
                    type: '!group',
                    subfields: {
                        shopify_placed_order: {
                            label: 'Placed order',
                            type: 'number_over_timeframe',
                            fieldSettings: {min: 1}
                        },
                        shopify_cancelled_order: {
                            label: 'Cancelled',
                            type: 'number_over_timeframe',
                            fieldSettings: {min: 1}
                        },
                        shopify_fulfilled_order: {
                            label: 'Fulfilled',
                            type: 'number_over_timeframe',
                            fieldSettings: {min: 1}
                        },
                        shopify_refunded_order: {
                            label: 'Refunded',
                            type: 'number_over_timeframe',
                            fieldSettings: {min: 1}
                        },
                        shopify_checkout_started: {
                            label: 'Checkout started',
                            type: 'number_over_timeframe',
                            fieldSettings: {min: 1}
                        }
                    }
                }
            }
        },
        profile: {
            label: 'Profile info',
            type: '!struct',
            subfields: {
                prop_value: {
                    type: '!group',
                    label: 'Properties',
                    subfields: {...sortedProfilePropValueFields}
                },
                profile_attribute_value: {
                    type: '!group',
                    label: 'Attributes',
                    subfields: {
                        email: {label: 'Email', type: 'text'},
                        email_domain: {label: 'Email Domain', type: 'text'},
                        last_send_at: {label: 'Last Sent Any Email Date', type: 'datetimeRange'},
                        last_open_at: {label: 'Last Opened Any Email Date', type: 'datetimeRange'},
                        last_click_at: {label: 'Last Clicked Any Email Date', type: 'datetimeRange'},
                        created_at: {label: 'Date Created in Elemental', type: 'datetimeRange'},
                        pmta_queue_id: {
                            label: 'PMTA Queue',
                            type: 'select',
                            fieldSettings: {
                                valueSources: ['value'],
                                addCustomValues: false,
                                showSearch: true,
                                asyncFetch: getPmtaQueues
                                // listValues?: ListValues,
                                // allowCustomValues?: boolean,
                                // showSearch?: boolean,
                                // showCheckboxes?: boolean,
                                // asyncFetch?: AsyncFetchListValuesFn,
                                // useLoadMore?: boolean,
                                // useAsyncSearch?: boolean,
                                // forceAsyncSearch?: boolean,

                            }
                        }
                    }
                }
            }
        },
        utility: {
            label: 'Utility',
            type: '!struct',
            subfields: {
                random_sample: {
                    label: 'Is in random sample of [N] contacts',
                    type: 'number',
                    fieldSettings: {
                        min: 1,
                        max: 100000000  // Adjust max as needed
                    },
                    preferWidgets: ['number'],
                    valueSources: ['value'],
                    operators: ['eq']
                }
            }
        },

    };
    const conjunctions = {
        AND: MuiConfig.conjunctions.AND,
        OR: MuiConfig.conjunctions.OR
    };

    const proximity = {
        ...MuiConfig.operators.proximity,
        valueLabels: [
            {label: 'Word 1', placeholder: 'Enter first word'},
            {label: 'Word 2', placeholder: 'Enter second word'}
        ],
        textSeparators: [
            //'Word 1',
            //'Word 2'
        ],
        options: {
            ...MuiConfig.operators.proximity.options,
            optionLabel: 'Near', // label on top of "near" selectbox (for config.settings.showLabels==true)
            optionTextBefore: 'Near', // label before "near" selectbox (for config.settings.showLabels==false)
            optionPlaceholder: 'Select words between', // placeholder for "near" selectbox
            minProximity: 2,
            maxProximity: 10,
            defaults: {
                proximity: 2
            },
            customProps: {}
        }
    };

    const operators = {
        ...MuiConfig.operators,
        between: {
            ...MuiConfig.operators.between,
            valueLabels: ['1', '2'],
            textSeparators: ['from', 'to']
        },
        // any: { label: 'is anything', jsonLogic: 'any' },
        // ne: { label: 'does not equal', jsonLogic: 'ne' },
        eq: {label: 'exactly', jsonLogic: 'eq'},
        gt: {label: 'more than', jsonLogic: 'gt'},
        lt: {label: 'less than', jsonLogic: 'lt'},
        ge: {label: 'at least', jsonLogic: 'ge'},
        le: {label: 'at most', jsonLogic: 'le'},
        // at_least_once: { label: 'at least once', jsonLogic: 'at_least_once' },
        // is_at_least: { label: 'is at least', jsonLogic: 'is_at_least' },
        // is_at_most: { label: 'is at most', jsonLogic: 'is_at_most' },
        // zero_times: { label: 'zero times', jsonLogic: 'zero_times' },
        // set: { label: 'set', jsonLogic: 'set' },
        // not_set: { label: 'not set', jsonLogic: 'not_set' },
        contains: {label: 'contains', jsonLogic: 'contains'},
        not_contains: {label: 'does not contain', jsonLogic: 'not_contains'},
        startswith: {label: 'starts with', jsonLogic: 'startswith'},
        endswith: {label: 'ends with', jsonLogic: 'endswith'},
        not_endswith: {label: 'does not end with', jsonLogic: 'not_endswith'},
        is: {label: 'is', jsonLogic: 'is'},
        in__delimited_comma: {label: 'is one of ... ( separate values with commas )' },
        proximity
    };

    const widgets = {
        ...MuiConfig.widgets,
        number_over_timeframe: {
            type: 'number_over_timeframe',
            valueSrc: 'value',
            factory: (props, val) => (<NumberOverTimeFrame {...props} />),
            formatValue: (val, _fieldDef, _wgtDef, isForDisplay, _op, _opDef) => {
                return isForDisplay && JSON.stringify(val);
            }
        },
        profile_prop_change: {
            type: 'profile_prop_change',
            valueSrc: 'value',
            factory: (props, val) => (<ProfilePropChangeWidget {...props} />),
            formatValue: (val, _fieldDef, _wgtDef, isForDisplay, _op, _opDef) => {
                return isForDisplay && JSON.stringify(val);
            }
        },
        datetimeRange: {
            type: 'datetimeRange',
            valueSrc: 'value',
            factory: (props, val) => (<DateRange {...props} />),
            formatValue: (val, _fieldDef, _wgtDef, isForDisplay, _op, _opDef) => {
                return isForDisplay && JSON.stringify(val);
            },
            hideOperator: true,
            operators: []
        },
        customDate: {
            type: 'customDate',
            valueSrc: 'value',
            factory: (props, val) => (<CustomDateComponent {...props} />),
            formatValue: (val, _fieldDef, _wgtDef, isForDisplay, _op, _opDef) => {
                return isForDisplay && JSON.stringify(val);
            }
        },
        customBoolean: {
            type: 'customBoolean',
            valueSrc: 'value',
            factory: (props, val) => (<CustomBooleanComponent {...props} type='bool'/>),
            formatValue: (val, _fieldDef, _wgtDef, isForDisplay, _op, _opDef) => {
                return isForDisplay && JSON.stringify(val);
            }
        },
        date: {
            ...MuiConfig.widgets.date,
            dateFormat: 'DD.MM.YYYY',
            valueFormat: 'YYYY-MM-DD'
        },
        time: {
            ...MuiConfig.widgets.time,
            timeFormat: 'HH:mm',
            valueFormat: 'HH:mm:ss'
        },
        datetime: {
            ...MuiConfig.widgets.datetime,
            timeFormat: 'HH:mm',
            dateFormat: 'DD.MM.YYYY',
            valueFormat: 'YYYY-MM-DD HH:mm:ss'
        },
        func: {
            ...MuiConfig.widgets.func,
            customProps: {
                shownumber_over_timeframe: true
            }
        },
        treeselect: {
            ...MuiConfig.widgets.treeselect,
            customProps: {
                shownumber_over_timeframe: true
            }
        }
    };

    const types = {
        ...MuiConfig.types,
        number_over_timeframe: {
            defaultOperator: 'eq',
            valueSources: ['value'],
            widgets: {
                number_over_timeframe: {
                    // operators: [ 'gt' ]
                    // operators: ['eq', 'ne', 'gt', 'lt', 'ge', 'le']
                    operators: ['eq', 'ne', 'gt', 'ge', 'lt', 'le', 'at_least_once', 'is_at_least', 'is_at_most', 'zero_times']
                }
            }
        },
        datetimeRange: {
            defaultOperator: 'between',
            valueSources: ['value'],
            widgets: {
                datetimeRange: {
                    operators: ['is'],
                    widgetProps: {
                        hideOperator: true
                    }
                }
            }
        },

        profile_prop_change: {
            defaultOperator: 'is',
            valueSources: ['value'],
            widgets: {
                profile_prop_change: {
                    operators: ['is'],
                    widgetProps: {
                        hideOperator: true
                        // showOperatorLabel: false,
                        // operatorInlineLabel: 'go for it'
                    }
                }
            }
        },
        customDate: {
            defaultOperator: 'eq',
            valueSources: ['value'],
            widgets: {
                customDate: {
                    operators: ['eq', 'ne']
                }
            }
        },

        customBoolean: {
            defaultOperator: 'eq',
            valueSources: ['value'],
            widgets: {
                customBoolean: {
                    operators: ['eq']
                }
            }
        },
        text: {
            defaultOperator: 'eq',
            valueSources: ['value'],
            widgets: {
                text: {
                    operators: ['eq', 'ne', 'contains', 'not_contains', 'startswith', 'not_startswith', 'endswith', 'not_endswith', 'in__delimited_comma']
                }
            }
        },
        number: {
            defaultOperator: 'eq',
            valueSources: ['value'],
            widgets: {
                number: {
                    // operators: ['eq', 'ne', 'gt', 'lt', 'ge', 'le']
                    operators: ['eq', 'ne', 'gt', 'ge', 'lt', 'le', 'is_at_least', 'is_at_most']
                }
            }
        },
        boolean: merge(MuiConfig.types.boolean, {
            defaultOperator: 'eq',
            widgets: {
                boolean: {
                    operators: ['eq'],
                    widgetProps: {
                        hideOperator: true,
                        showOperatorLabel: false,
                        operatorInlineLabel: 'is'
                    }
                }
            }
        })
    };
    const localeSettings = {
        locale: {
            moment: 'ru'
        },
        valueLabel: 'Value',
        valuePlaceholder: 'Value',
        fieldLabel: 'Field',
        operatorLabel: 'Operator',
        fieldPlaceholder: 'Select field',
        operatorPlaceholder: 'Select operator',
        deleteLabel: null,
        addGroupLabel: 'Add group',
        addRuleLabel: 'Add rule',
        delGroupLabel: null,
        addSubRuleLabel: 'Add sub rule',
        valueSourcesPopupTitle: 'Select value source'
        // notLabel: 'Not',
        // removeRuleConfirmOptions: {
        //   title: "Are you sure delete this rule?",
        //   okText: "Yes",
        //   okType: "danger",
        // },
        // removeGroupConfirmOptions: {
        //   title: "Are you sure delete this group?",
        //   okText: "Yes",
        //   okType: "danger",
        // },
    };

    const settings = {
        ...MuiConfig.settings,
        ...localeSettings,
        // valueSourcesInfo: {},
        // canReorder: false,
        // canRegroup: false,
        // showLabels: true,
        showNot: false,
        maxNesting: Infinity,
        canLeaveEmptyGroup: true
        // renderField: (props) => <FieldCascader {...props} />,
        // renderOperator: (props) => <FieldDropdown {...props} />,
        // renderFunc: (props) => <FieldSelect {...props} />,
    };

    const funcs = {
        LOWER: {
            label: 'Lowercase',
            mongoFunc: '$toLower',
            jsonLogic: ({str}) => ({'method': [str, 'toLowerCase']}),
            returnType: 'text',
            args: {
                str: {
                    label: 'String',
                    type: 'text',
                    valueSources: ['value', 'field']
                }
            }
        },
        LINEAR_REGRESSION: {
            label: 'Linear regression',
            returnType: 'number',
            formatFunc: ({coef, bias, val}, _) => `(${coef} * ${val} + ${bias})`,
            sqlFormatFunc: ({coef, bias, val}) => `(${coef} * ${val} + ${bias})`,
            mongoFormatFunc: ({coef, bias, val}) => ({'$sum': [{'$multiply': [coef, val]}, bias]}),
            jsonLogic: ({coef, bias, val}) => ({'+': [{'*': [coef, val]}, bias]}),
            renderBrackets: ['', ''],
            renderSeps: [' * ', ' + '],
            args: {
                coef: {
                    label: 'Coef',
                    type: 'number',
                    defaultValue: 1,
                    valueSources: ['value']
                },
                val: {
                    label: 'Value',
                    type: 'number',
                    valueSources: ['value']
                },
                bias: {
                    label: 'Bias',
                    type: 'number',
                    defaultValue: 0,
                    valueSources: ['value']
                }
            }
        }
    };
    return {
        conjunctions,
        operators,
        widgets,
        types,
        settings,
        fields,
        funcs
    };
};

export default getQueryBuilderConfig;
