import humanize from 'humanize-plus';
import {Tooltip, Chip} from '@mui/material';
import React from 'react';

const TooltipBigNumber = ({ number, children, compact = false, value = null }) => {

    if ( value ) {
        number = value;
    }

    if (number === null || number === undefined || number === 'unknown') {
        return '-';
    }

    const formattedNumber = humanize.intComma(number);
    const compactNumber = compact ? humanize.compactInteger(number, 1) : formattedNumber;

    // If children are provided, clone them with the number values
    if (children) {
        return (
            <Tooltip title={formattedNumber} arrow>
                {React.cloneElement(children, { 
                    label: compactNumber,
                    ...children.props 
                })}
            </Tooltip>
        );
    }

    // Default rendering with a Chip
    return (
        <Tooltip title={formattedNumber} arrow>
            <Chip
                label={compactNumber}
                size="small"
                variant="outlined"
                sx={{ 
                    height: '20px',
                    '& .MuiChip-label': { 
                        px: 1,
                        fontSize: '0.75rem'
                    }
                }}
            />
        </Tooltip>
    );
};

export default TooltipBigNumber;